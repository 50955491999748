// The values should be the same as the url parameter keys
export const AdCookieName = {
  // Google Ads
  Gclid: 'gclid',
  GBraid: 'gbraid',
  WBraid: 'wbraid',
  // Appcast
  UtmSource: 'utm_source',
  UtmTerm: 'utm_term',
} as const;

export type AdCookieNameType = (typeof AdCookieName)[keyof typeof AdCookieName];
