import { useRouter } from 'next/router';
import {
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { NavigationDocument, UpdateClickIdsDocument } from '@generated/graphql';
import TrustedLogomark from '@public/static/trusted_logomark_mint_black.svg';
import TrustedLogomarkFullBlack from '@public/static/trusted_wordmark_black.svg';
import { NavigationProfileDropdown } from '@src/components/navigation-profile-dropdown';
import { NavigationTopBarUser } from '@src/components/navigation/navigation-top-bar-user';
import { SystemMessage } from '@src/components/system-message';
import { SEGMENT_EVENTS } from '@src/constants';
import { USER_VIEWED_CHAT_PANEL } from '@src/constants/segment-events/home';
import { useMutation, useQuery } from '@src/hooks/use-graphql-hooks';
import { useIsMobile } from '@src/hooks/use-is-mobile';

import { NavigationBottomBar } from '@src/components/navigation/navigation-bottom-bar';
import type { NavigationLinkDefinition } from '@src/components/navigation/navigation-link-definition';
import { NavigationSideMenu } from '@src/components/navigation/navigation-side-menu';
import { isProfileBlocked } from '@src/containers/profile-page-v2/profile-page-v2-utils';
import { useBottomAppBarOverrides } from '@src/contexts/bottom-app-bar-overrides';
import { useChat } from '@src/contexts/chat-provider';
import { useTopAppBarOverrides } from '@src/contexts/top-app-bar-overrides';
import { getClickAdIds } from '@src/domains/foundation/tracking/cookies/tracking-cookies';
import { useMobileWebview } from '@src/hooks/use-mobile-webview';
import { analytics } from '@src/lib/analytics';
import * as colors from '@src/support/colors';
import { AdminSessionBanner } from '@src/ui/admin-session-banner';
import { FontAwesomeIcon } from '@src/ui/font-awesome-icon';
import {
  Nav,
  NavMain,
  NavMainContent,
  NavMainHeader,
  NavMainHeaderBar,
  NavMainHeaderBarAction,
  NavMainHeaderBarActions,
  NavMainHeaderBarLogo,
  NavMainHeaderBarTitle,
  NavSidebar,
  NavSidebarCard,
  NavSidebarCardHeader,
  NavSidebarCardMenu,
  NavSidebarLogo,
} from '@src/ui/nav';
import { Scroll, ScrollView } from '@src/ui/scroll';
import { getUser } from '@src/utils/cookies';
import { capitalize } from '@src/utils/presentation';
import { nonNullValues } from '@src/utils/types';
import { isUser, maybeUser } from '@src/utils/user-utils';
import { CountBadge } from './count-badge';
import { NavigationBarLink } from './navigation-bar-link';
import { useTrackNavigationClick } from './use-track-navigation-click';

type Props = {
  fallbackTitle: ReactNode;
  actions?: ReactNode;
  appBarBottom?: ReactNode;
  mobileTitle?: string;
};

export function Navigation({
  children,
  appBarBottom,
  fallbackTitle,
  mobileTitle,
}: PropsWithChildren<Props>) {
  const chat = useChat();
  const { appBarState } = useTopAppBarOverrides();
  const { bottomBarState } = useBottomAppBarOverrides();
  const router = useRouter();
  const [profileOpen, setProfileOpen] = useState(false);
  const divReference = useRef<HTMLDivElement | null>(null);
  const dropdownReference = useRef<HTMLDivElement>(null);
  const cookieUser = getUser();
  const { data, loading } = useQuery(NavigationDocument);
  const { profileCompletionSummary, badgesItemsCount } = data || {};
  const user = maybeUser(data?.viewer);
  const hasUser = isUser(user);
  const isMobileBrowser = useIsMobile(true);
  const mobileWebView = useMobileWebview();
  const isProfileComplete =
    profileCompletionSummary?.meta?.percentCompleted === 100;
  const nurseProfile = user?.nurseProfile;
  const credentialsCount = badgesItemsCount?.credentialsCount || 0;
  const applicationCount =
    (nurseProfile?.jobApplicationsSummary.actionRequired.length || 0) +
    (nurseProfile?.jobApplicationsSummary.offer.length || 0);
  const matchesCount = badgesItemsCount?.matchesCount || 0;
  const profileCount = badgesItemsCount?.completionItemsCount || 0;
  const profileHasBadge =
    profileCompletionSummary && // This is to prevent isProfileBlocked from throwing an error
    (profileCount > 0 ||
      profileCompletionSummary.steps.some(
        s => s.hasExpiredItems || s.hasExpiringItems || s.hasInvalidItems
      )) &&
    !isProfileBlocked({ user, profileCompletionSummary });

  const [updateClickAds] = useMutation(UpdateClickIdsDocument);

  useEffect(() => {
    if (!mobileWebView.isPresent && isMobileBrowser && isProfileComplete) {
      const script = document.createElement('script');
      script.src = '/static/smart-banner.js';
      script.id = 'smartbanner-script';
      script.async = true;
      document.body.appendChild(script);

      // Cleanup function
      return () => {
        const existingScript = document.getElementById('smartbanner-script');
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      };
    }
  }, [isMobileBrowser, mobileWebView.isPresent, isProfileComplete]);

  const [isSideMenuOpen, setNavSideMenuOpen] = useState(false);

  const openChat = useCallback(
    (source: string) => {
      analytics.track(USER_VIEWED_CHAT_PANEL, { source });
      chat.open();
    },
    [chat]
  );

  const handleChatButtonClick = () => openChat('Navigation');

  useEffect(() => {
    if (router.query.openPanel === 'openChatPanel') {
      const source = router.pathname
        .split('/')
        .map(path => capitalize(path).trim())
        .join('');
      openChat(source);
    }
  }, [openChat, router.pathname, router.query.openPanel]);

  // TODO: check if dependency is needed
  // biome-ignore lint/correctness/useExhaustiveDependencies: router.asPath
  useEffect(() => setProfileOpen(false), [router.asPath]);

  useEffect(() => {
    const { gclid, gbraid, wbraid, utmSource, utmTerm } = getClickAdIds();

    if (gclid || gbraid || wbraid || utmTerm) {
      updateClickAds({
        variables: {
          gclid,
          gbraid,
          wbraid,
          utmSource,
          utmTerm,
        },
      });
    }
  }, [updateClickAds]);

  const hasBeenPlaced = nurseProfile?.hasBeenPlaced ?? false;
  const clinicianNotNew = nurseProfile?.state !== 'new';

  const hideNavActions = isMobileBrowser && appBarState.leading;
  const favoritesHref = '/matches/favorites';
  const applicationsHref = '/matches/applications';

  const trackNavigation = useTrackNavigationClick();

  const navLinks = {
    home: {
      label: 'Home',
      href: '/home',
      icon: ['fal', 'house'],
      activeIcon: 'house',
      visible: true,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HOME_LINK),
    },
    profile: {
      label: 'Profile',
      href: '/profile',
      icon: ['fal', 'user-doctor'],
      activeIcon: 'user-doctor',
      visible: hasUser && clinicianNotNew,
      count: profileHasBadge ? 1 : 0,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_PROFILE_LINK),
    },
    matches: {
      label: 'Matches',
      href: '/matches/filtering',
      activeIcon: 'magnifying-glass',
      icon: ['fal', 'magnifying-glass'],
      visible: hasUser && clinicianNotNew,
      count: matchesCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_MATCHES_LINK),
    },
    apply: {
      label: 'Apply',
      href: applicationCount > 0 ? applicationsHref : favoritesHref,
      activeIcon: 'heart',
      icon: ['fal', 'heart'],
      visible: hasUser && clinicianNotNew,
      count: applicationCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_APPLY_LINK),
    },
    chat: {
      label: 'Chat',
      activeIcon: 'comment-alt',
      icon: ['fal', 'comment-alt'],
      visible: true,
      onClick: handleChatButtonClick,
    },
    credentials: {
      label: 'Credentials',
      href: '/credentials',
      activeIcon: 'copy',
      icon: ['fal', 'copy'],
      visible: hasUser && clinicianNotNew,
      count: credentialsCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_CREDENTIALS_LINK),
    },
    invite: {
      label: 'Invite',
      href: '/invite',
      activeIcon: 'gift',
      icon: ['fal', 'gift'],
      visible: hasUser && clinicianNotNew,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_INVITE_LINK),
    },
    onboarding: {
      label: 'Onboarding',
      href: '/onboarding',
      activeIcon: 'tasks',
      icon: ['far', 'tasks'],
      visible: hasUser && clinicianNotNew && !!nurseProfile?.isOnboarding,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_ONBOARDING_LINK),
    },
    payAndBenefits: {
      label: 'Pay & Benefits',
      href: 'https://workforcenow.adp.com/workforcenow/login.html',
      activeIcon: 'heartbeat',
      icon: ['fal', 'heartbeat'],
      visible: hasUser && clinicianNotNew && hasBeenPlaced,
      external: true,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_PAY_BENEFITS_LINK
        ),
    },
    helpCenter: {
      label: 'Help Center',
      href: 'https://help.trustedhealth.com',
      activeIcon: 'question-circle',
      icon: ['fal', 'question-circle'],
      visible: true,
      external: true,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HELP_CENTER_LINK),
    },
    account: {
      label: 'Account',
      href: '/account',
      activeIcon: 'cog',
      icon: ['fal', 'cog'],
      visible: true,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_ACCOUNT_LINK),
    },
    explorer: {
      label: 'Explorer',
      href: '/explorer',
      activeIcon: 'compass',
      icon: ['fal', 'compass'],
      visible: hasUser && clinicianNotNew,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_EXPLORER_LINK),
    },
    community: {
      label: 'Community',
      href: 'https://www.facebook.com/groups/thetrustedcircle',
      activeIcon: 'users',
      icon: ['fal', 'users'],
      visible: hasUser && clinicianNotNew,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_COMMUNITY_LINK),
    },
    mobileDevMagic: {
      label: 'Mobile Dev Magic',
      activeIcon: 'magic',
      icon: ['fal', 'magic'],
      visible: hasUser && mobileWebView.isDevMagicAllowed,
      onClick: () => {
        mobileWebView.postMessage({ action: 'OPEN_DEV_MENU' });
      },
    },
  } satisfies Record<string, NavigationLinkDefinition>;

  const visibleBarLinks = (links: NavigationLinkDefinition[]) => {
    return links
      .filter(definition => definition.visible)
      .map(definition => (
        <NavigationBarLink
          key={definition.label}
          href={definition.href!}
          activeIcon={definition.activeIcon}
          icon={definition.icon}
          target={definition.external ? '_blank' : undefined}
          rel={definition.external ? 'noreferrer noopener' : undefined}
          count={definition.count}
          onClick={definition.onClick}
        >
          {definition.label}
        </NavigationBarLink>
      ));
  };

  const bottomBarLinks = visibleBarLinks([
    {
      ...navLinks.home,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HOME_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.profile,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_PROFILE_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.matches,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_MATCHES_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.apply,
      label: 'Apply',
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_APPLY_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.invite,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_INVITE_LINK,
          'bottom'
        ),
    },
  ]);

  const renderAppBarLeading = () => {
    if (appBarState.leading) {
      return (
        <NavMainHeaderBarActions position="left">
          {appBarState.leading}
          <NavMainHeaderBarTitle onClick={() => setProfileOpen(false)}>
            {appBarState.navTitle || fallbackTitle}
          </NavMainHeaderBarTitle>
        </NavMainHeaderBarActions>
      );
    }

    return isMobileBrowser ? (
      <NavMainHeaderBarActions position="left">
        <NavMainHeaderBarAction onClick={() => setNavSideMenuOpen(true)}>
          <FontAwesomeIcon icon={['fal', 'bars']} fixedWidth />
        </NavMainHeaderBarAction>
      </NavMainHeaderBarActions>
    ) : (
      <NavMainHeaderBarTitle onClick={() => setProfileOpen(false)}>
        {appBarState.navTitle || fallbackTitle}
      </NavMainHeaderBarTitle>
    );
  };

  const referText = navLinks.invite.visible
    ? data?.saasquatchMainPageWidget?.titleText
    : undefined;

  const renderTopAppBarTop = () => (
    <>
      {cookieUser?.isAdmin && user && (
        <AdminSessionBanner
          adminName={cookieUser.adminName}
          isAdmin={cookieUser.isAdmin}
          user={user}
        />
      )}
      <NavMainHeaderBar>
        {renderAppBarLeading()}
        {!appBarState.leading &&
          (!!mobileTitle && isMobileBrowser ? (
            <div className="MobileTitle">{mobileTitle}</div>
          ) : (
            <NavMainHeaderBarLogo href={navLinks.home.href}>
              <TrustedLogomarkFullBlack width="110" />
            </NavMainHeaderBarLogo>
          ))}
        {!hideNavActions && (
          <NavMainHeaderBarActions>
            <>
              {!loading && navLinks.chat.visible && (
                <NavMainHeaderBarAction onClick={navLinks.chat.onClick}>
                  <CountBadge color="secondary" count={chat.unreadCount}>
                    <FontAwesomeIcon
                      className="fix-chat-icon"
                      icon={navLinks.chat.icon}
                      fixedWidth
                    />
                  </CountBadge>
                </NavMainHeaderBarAction>
              )}
              {!isMobileBrowser && (
                <NavigationTopBarUser
                  loading={loading}
                  anchorRef={divReference}
                  open={profileOpen}
                  user={user}
                  onClick={() => setProfileOpen(!profileOpen)}
                  clinicianNotNew={clinicianNotNew}
                />
              )}
            </>
          </NavMainHeaderBarActions>
        )}

        {profileCompletionSummary && user && (
          <NavigationProfileDropdown
            user={user}
            open={profileOpen}
            anchorRef={divReference}
            reference={dropdownReference}
            onClose={() => setProfileOpen(false)}
            clinicianNotNew={clinicianNotNew}
            referText={referText}
          />
        )}
        {appBarState.actions}
      </NavMainHeaderBar>
      <style jsx>{`
        .MobileTitle {
          align-self: center;
          text-align: center;
          color: ${colors.black};
          line-height: 1.5rem;
          font-size: 1.25rem;
        }
      `}</style>
    </>
  );

  const renderTopAppBarBottom = () => {
    const effectiveBottomContent = appBarState.bottomContent || appBarBottom;
    const effectiveSystemMessages = data?.systemMessages
      ?.filter(nonNullValues)
      ?.map(message => <SystemMessage key={message.id} body={message.body} />);

    if (!effectiveSystemMessages?.length && !effectiveBottomContent) {
      return;
    }

    return (
      <>
        {effectiveSystemMessages}
        {effectiveBottomContent}
      </>
    );
  };

  const renderSideBar = () => {
    if (isMobileBrowser) {
      return (
        <>
          <NavigationSideMenu
            user={user}
            open={isSideMenuOpen}
            onClose={() => setNavSideMenuOpen(false)}
            referText={referText}
            links={[
              navLinks.home,
              navLinks.profile,
              navLinks.matches,
              navLinks.apply,
              navLinks.credentials,
              navLinks.onboarding,
              navLinks.payAndBenefits,
            ].filter(definition => definition.visible)}
            extraLinks={[
              navLinks.account,
              navLinks.explorer,
              navLinks.community,
              navLinks.helpCenter,
              navLinks.mobileDevMagic,
            ].filter(definition => definition.visible)}
          />
        </>
      );
    }

    return (
      <NavSidebar>
        <NavSidebarCard>
          <NavSidebarCardHeader>
            <NavSidebarLogo href={navLinks.home.href}>
              <TrustedLogomark />
            </NavSidebarLogo>
          </NavSidebarCardHeader>
          <Scroll>
            <ScrollView>
              <NavSidebarCardMenu>
                {visibleBarLinks([
                  navLinks.home,
                  navLinks.profile,
                  navLinks.matches,
                  navLinks.apply,
                  navLinks.credentials,
                  navLinks.onboarding,
                  navLinks.payAndBenefits,
                  navLinks.invite,
                ])}
              </NavSidebarCardMenu>
            </ScrollView>
          </Scroll>
        </NavSidebarCard>
      </NavSidebar>
    );
  };

  return (
    <>
      <Nav>
        {renderSideBar()}
        <div className="main-content">
          <div className="container-for-sticky-top-app-bar">
            <NavMain>
              <NavMainHeader>
                {renderTopAppBarTop()}
                {renderTopAppBarBottom()}
              </NavMainHeader>
              {/* We have public/smart-banner.js script that looks for smart-banner className/id to add app banner. */}
              <div className="smart-banner" id="smart-banner" />
              <NavMainContent>{children}</NavMainContent>
            </NavMain>
          </div>
          {bottomBarLinks.length > 1 && !bottomBarState.hideBottom && (
            <NavigationBottomBar>{bottomBarLinks}</NavigationBottomBar>
          )}
        </div>
      </Nav>
      <style jsx>
        {`
          .main-content {
            display: flex;
            flex: 1;
            flex-direction: column;
          }

          // chat icon doesn't align properly with the other icon
          .main-content :global(.fix-chat-icon) {
            margin-top: 2px;
            height: calc(1.25rem - 1px);
          }

          .container-for-sticky-top-app-bar {
            display: flex;
            overflow: auto;
            flex: 1;
          }
        `}
      </style>
    </>
  );
}
